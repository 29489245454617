$user_status_inner_width_l: 78%;
$user_status_inner_width_s: 88%;
$detail_inner_width_l: 95%;
$detail_inner_width_s: 98%;

.status_inner {
  background-color: $white_alpha;
  height: 100%;
}

.arrows_wrapper {
  button,
  button:hover,
  button:focus,
  button.focus,
  button:not(:disabled):not(.disabled):active,
  button:not(:disabled):not(.disabled).active,
  button:not(:disabled):not(.disabled):active:focus,
  button:not(:disabled):not(.disabled).active:focus {
    background-color: transparent;
    border: 0;
    outline: 0;
  }
  .arrow {
    @include size(40px, 115px);
    overflow: visible;
    path {
      opacity: 1;
      fill: transparent;
      stroke: $gray;
      stroke-width: 8px;
      stroke-linejoin: miter;
      stroke-linecap: square;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }
  }
  .down {
    @include position_fixed(auto, 0, 0, 0);
    text-align: center;
    button {
      .arrow {
        @include transform(rotate(90deg));
      }
    }
  }
  .up {
    @include position_fixed(0, 0, auto, 0);
    text-align: center;
    button {
      .arrow {
        @include transform(rotate(-90deg));
      }
    }
  }
  .right {
    @include position_fixed(calc(50% - 52px), 40px, auto, auto);
  }
  .left {
    @include position_fixed(calc(50% - 52px), auto, auto, 40px);
      button{
        .arrow{
          @include transform(rotate(180deg));
        }
      }
  }
}

.user_status {
  @include flexbox(column, center, space-around);
  width: 48%;
  .title {
    @include size($user_status_inner_width_l, 15%);
    background: $logo_hex no-repeat -10px -10px;
    margin-top: 5%;
    position: relative;
    h2 {
      @include position_absolute(30px, auto, auto, 120px);
      color: $black;
      font: bold 60px $font_josefin_sans;
      letter-spacing: -1px;
    }
  }
  .name {
    @include size($user_status_inner_width_l, 10%);
    @include flexbox(column, center, center);
    background-color: $white;
    border: solid $black 3px;
    box-sizing: border-box;
    h3 {
      font: bold 25px $font_josefin_sans,$font_basic;
    }
  }
  .table {
    @include size($user_status_inner_width_l, 55%);
    @include flexbox(row, center, center);
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    background-color: $white;
    border-right: solid $black 3px;
    border-top: solid $black 3px;
    box-sizing: border-box;
    text-align: center;
    .cell,
    .cell_top {
      div {
        @include size(100%, 50%);
        span {
          font: bold 16px $font_basic;
        }
        .small {
          font-size: .8em;
        }
      }
    }
    .cell {
      @include size(25%, 33%);
    }
    .cell_top {
      @include size(25%, 34%);
    }
    .cell_height_double {
      @include size(50%, 34%);
      div {
        @include size(100%, 100%);
        h3 {
          font: bold 18px $font_basic;
        }
      }
    }
    .cell,
    .cell_top,
    .cell_height_double {
      div {
        @include flexbox(column, center, center);
        border-left: solid $black 3px;
        border-bottom: solid $black 3px;
        box-sizing: border-box;
      }
      .pink { background-color: $table_pink; }
      .orange { background-color: $table_orange; }
      .blue { background-color: $table_blue; }
      .green { background-color: $table_green; }
    }
  }
}

.history {
  @include size(100%, 100%);
  @include flexbox(row, center, space-between);
  .history_charts_wrapper {
    @include flexbox(column, center, center);
    width: 50%;
    .history_charts_inner {
      @include size(94%, 94%);
      background-color: $white;
      position: relative;
      .chart_wrapper {
        @include size(350px, 350px);
        font-weight: bold;
        margin: 20px auto 50px;
      }
      .chart_small_wrapper {
        width: 100%;
        .chart_small_inner {
          @include flexbox(row, center, space-around);
          flex-direction: row-reverse;
          -webkit-flex-direction: row-reverse;
          .chart_small {
            font-size: 9px;
            h3 {
              font: bold 14px $font_basic;
              margin: 0;
              text-align: center;
            }
            .datetime {
              display: block;
              font: bold 12px $font_basic;
              text-align: center;
            }
            .recharts-wrapper {
              margin-top: -12px;
            }
          }
        }
        .order {
          @include size(500px, 0);
          @include position_absolute(auto, 0, 80px, 0);
          border-bottom: 7px solid $black;
          box-sizing: border-box;
          .arrow {
            @include size(28.88px, 32.5px);
            path {
              fill: $black;
            }
          }
          .newer {
            @include position_absolute(-12px, auto, auto, 480px);
          }
          .older {
            @include position_absolute(-12px, auto, auto, -10px);
            @include transform(rotate(180deg));
          }
        }
        .old,
        .new {
          box-sizing: border-box;
          color: $black;
          font: bold 25px $font_josefin_sans;
          margin: 0;
          padding: 0;
          text-align: left;
        }
        .old {
          @include position_absolute(auto, auto, 30px, 20px);
        }
        .new {
          @include position_absolute(auto, 20px, 30px, auto);
        }
      }
      .no_history {
        @include size(100%, 100%);
        @include flexbox(column, center, center);
        h3 {
          color: $gray;
        }
      }
    }
  }
}

.detail {
  @include size(100%, 100%);
  @include flexbox(row, center, space-between);
  .detail_wrapper {
    @include flexbox(column, center, center);
    width: 50%;
    .detail_inner {
      @include size($detail_inner_width_l, 90%);
      background-color: $white;
      position: relative;
      .chart_wrapper {
        @include size(300px, 300px);
        @include position_absolute(10px, 10px, auto, auto);
        font: bold 14px $font_basic;
        .recharts-wrapper {
          margin-top: -20px;
        }
      }
      .testset {
        @include size(calc(100% - 20px), 210px);
        @include position_absolute(345px, 0, auto, 0);
        .react-bootstrap-table {
          .table {
            th, td {
              width: 25%;
            }
          }
        }
      }
      .tags {
        @include size(calc(100% - 20px), auto);
        @include position_absolute(auto, 0, 20px, 0);
      }
      .react-datepicker {
        @include position_absolute(10px, auto, auto, 10px);
        border: 3px solid $black;
        border-radius: 0;
        box-sizing: border-box;
        font-family: $font_basic;
        .react-datepicker__header {
          background-color: $white;
        }
        .react-datepicker__current-month {
          font-size: 12px;
          text-align: left;
          padding-left: 40px;
        }
        .react-datepicker__day-name {
          @include size(30px, 20px);
          font-size: 10px;
          margin: 0 3px;
        }
        .react-datepicker__month {
          margin: 0;
        }
        .react-datepicker__week {
          box-sizing: border-box;
          border-bottom: solid 1px $light_gray;
        }
        .react-datepicker__week:last-child{
          border-bottom: 0;
        }
        .react-datepicker__day,
        .react-datepicker__day:hover {
          @include size(30px, 30px);
          border-radius: 50%;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin: 7.4px 3px;
        }
        .react-datepicker__day--highlighted {
          color: $black;
          background-color: $light_navy;
          &:hover {
            background-color: $light_navy_alpha;
          }
        }
      }
    }
  }
}

.start {
  .book_wrapper {
    @include size(80%, 60%);
    @include position_absolute(50px, 0, auto, 0);
    &:nth-of-type(2){
      @include flexbox(row, center, center)
    }
    .line_outer {
      overflow: visible;
      path {
        fill: $white;
        stroke: $black;
        stroke-width: 3px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 10;
        shape-rendering: auto;
      }
    }
    .line_center {
      @include size(2px, auto);
      @include position_absolute(26px, auto, auto, 50%);
      overflow: visible;
      width: 2px;
      path {
        fill: transparent;
        stroke: $black;
        stroke-width: 2px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 10;
        shape-rendering: auto;
      }
    }
    .weak_tags,
    .advices {
      @include size(50%, 100%);
      @include flexbox(column, center, center);
      .title {
        @include size(100%, 20%);
        @include flexbox(row, center, center);
        h3 {
          font-weight: bold;
          margin: 0 0 0 20px;
        }
        .hexagon {
          overflow: visible;
          width: 70px;
        }
      }
      .start_msg_wrapper {
        @include size(100%, 55%);
        @include flexbox(column, center, center);
        div {
          @include size(75%, 40%);
          position: relative;
          .num {
            @include position_absolute(auto, auto, 0, auto);
            display: block;
            font-weight: bold;
            width: 10%;
          }
          .tag, .msg {
            @include position_absolute(auto, auto, 0, 15%);
            display: block;
            font-weight: bold;
            width: 85%;
          }
          .tag {
            font-size: 45px;
            text-align: center;
          }
          .msg {
            font-size: 18px;
          }
        }
      }
    }
    .weak_tags {
      .title {
        h3 {
          font-size: 45px;
        }
        .hexagon {
          path {
              fill: $hex_1;
          }
        }
      }
      .start_msg_wrapper {
        div {
          border-bottom: $hex_1 3px solid;
          .num {
              color: $hex_1;
          }
        }
      }
    }
    .advices {
      .title {
        h3 {
          font-size: 38px;
        }
        .hexagon {
          path {
            fill: $hex_2;
          }
        }
      }
      .start_msg_wrapper {
        div {
          border-bottom: $hex_2 3px solid;
          .num {
              color: $hex_2;
          }
        }
      }
    }
  }
  .test_info {
    @include size(200px, 120px);
    @include flexbox(column, center, center);
    background-color: $white;
    border: solid $black 3px;
    box-sizing: border-box;
    text-align: center;
    h3 {
      @include size(90%, 35%);
      border-bottom: solid $black 3px;
      font: bold 20px $font_basic;
      margin: 0;
      line-height: 2em;
    }
  }
  .count {
    @include position_absolute(auto, auto, 35px, 50px);
    span {
      @include size(100%, 65%);
      font: bold 35px $font_basic;
    }
  }
  .time {
    @include position_absolute(auto, 50px, 35px, auto);
    span {
      @include size(100%, 45%);
      font: bold 35px $font_basic;
    }
    .time_ref {
      @include size(100%, 20%);
      font-size: 11px;
    }
  }
  .btn_start {
    @include size(350px, 150px);
    @include position_absolute(auto, 0, 35px, 0);
    border-radius: 25px;
    box-sizing: border-box;
    font-size: 40px;
    font-weight: bold;
  }
  .btn_start,
  .btn_start:hover,
  .btn_start:focus,
  .btn_start.focus,
  .btn_start:not(:disabled):not(.disabled):active,
  .btn_start:not(:disabled):not(.disabled).active,
  .btn_start:not(:disabled):not(.disabled):active:focus,
  .btn_start:not(:disabled):not(.disabled).active:focus {
    background-color: $btn_pink;
    border: solid $black 3px;
    color: $black;
    &:hover {
      background-color: $btn_pink_alpha;
    }
  }
}

.result {
  @include size(100%, 100%);
  @include flexbox(row, flex-start , space-between);
  .result_wrapper {
    background-color: $white_alpha;
    position: relative;
    &:nth-of-type(1){
      @include size(46%, 83%);
    }
    &:nth-of-type(2){
      @include size(52%, 83%);
    }
    .title_wrapper {
      @include position_absolute(20px, auto, auto, 10%);
    }
    .chart_wrapper {
      @include size(420px, 420px);
      @include position_absolute(25%, 0, auto, 0);
      background-color: $white;
      font-weight: bold;
      &>div {
        @include size(350px, 350px);
        margin: 20px auto;
      }
    }
    h3 {
      @include position_absolute(20px, auto, auto, 20px);
      font: bold 40px $font_josefin_sans;
    }
    .score {
      @include size(270px, 60px);
      @include position_absolute(20px, 20px, auto, auto);
      background-color: $white;
      border: 3px solid $black;
      font: bold 22px $font_noto_sans;
      line-height: 52px;
      text-align: center;
    }
    .result_inner {
      @include size($detail_inner_width_l, calc(100% - 215px));
      @include position_absolute(90px, 0, auto, 0);
      background-color: $white;
      .tags {
        @include size($detail_inner_width_l, auto);
        @include position_absolute(10px, 0, auto, 0);
      }
      .testset {
        @include size($detail_inner_width_l, 245px);
        @include position_absolute(115px, 0, auto, 0);
        .react-bootstrap-table {
          .table {
            th, td {
                width: 20%;
            }
          }
        }
        .checkbox_head {
          @include position_absolute(3px, auto, auto, 3px);
          font-size: 12px;
          font-weight: bold;
          z-index: 999;
        }
      }
      .output {
        @include position_absolute(auto, auto, 10px, 15px);
        font-size: 12px;
        font-weight: bold;
        .btn_pdf {
          font-size: 13px;
          font-weight: bold;
          margin-right: 10px;
          width: 60px;
        }
        .btn_pdf,
        .btn_pdf:hover,
        .btn_pdf:focus,
        .btn_pdf.focus,
        .btn_pdf:not(:disabled):not(.disabled):active,
        .btn_pdf:not(:disabled):not(.disabled).active,
        .btn_pdf:not(:disabled):not(.disabled):active:focus,
        .btn_pdf:not(:disabled):not(.disabled).active:focus {
          border: None;
          background-color: #4169e1;
          box-shadow: None;
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    .advice_wrapper {
      @include size($detail_inner_width_l, 95px);
      @include position_absolute(auto, 0, 25px, 0);
      * {
        font-weight: bold;
      }
      h4 {
        font-size: 15px;
        margin: 0;
      }
      .advice {
        @include size(100%, 80px);
        background-color: $white;
        border: $black solid 2px;
        font-size: 15px;
        padding: 4px 8px;
      }
    }
  }
  .btn_group {
    @include size(400px, 15%);
    @include position_absolute(auto, 0, 0, 0);
    @include flexbox(row, center, space-between);
    .btn_back {
      @include size(200px, 100%);
      background-color: $pink;
    }
    .btn_end {
      @include size(150px, 100%);
      background-color: $red;
      span:after {
        content: '\A(画面を閉じる)';
        white-space: pre;
      }
    }
    .btn_,
    .btn_:hover,
    .btn_:focus,
    .btn_.focus,
    .btn_:not(:disabled):not(.disabled):active,
    .btn_:not(:disabled):not(.disabled).active,
    .btn_:not(:disabled):not(.disabled):active:focus,
    .btn_:not(:disabled):not(.disabled).active:focus {
      border: solid $black 2px;
      border-radius: 30px;
      box-sizing: border-box;
      color: $white;
      font-size: 20px;
      font-weight: bold;
      padding: 0;
      &:hover {
        background-color: $pink_alpha;
      }
    }
  }
}
.logout_msg {
  color: $dark_gray;
  font: 20px $font_basic;
  margin: 10px;
}

.title_wrapper {
  @include size(340px, 90px);
  @include flexbox(row, center, center);
  @include position_absolute(20px, auto, auto, 3%);
  .title_logo {
    @include size(30%, 100%);
    @include flexbox(column, center, center);
    background: $logo_hex no-repeat center;
    background-size: contain;
  }
  .title {
    @include size(70%, 100%);
    @include flexbox(row, center, center);
    border-bottom: 4px #000 solid;
    h2 {
      @include size(100%, auto);
      font-size: 28px;
      font-weight: bold;
    }
  }
}

.react-bootstrap-table {
  @include size(50%, 100%);
  border: 1px solid $black;
  display: block;
  overflow-y: scroll;
  table-layout: fixed;
  .table {
    border: 0;
    color: $black;
    font-size: 12px;
    text-align: center;
    width: 100%;
    tr {
      display: block;
      width: 100%;
    }
    th,
    td {
      border: 0;
      display: inline-block;
      padding: 2px;
    }
    thead,
    tbody {
      border: 0;
      display: block;
      width: 100%;
    }
    thead {
      background-color: $white;
      border-bottom: 1px solid $black;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      th {
        border: 0;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $light_gray;
        &:focus {
          background-color: $light_blue;
        }
      }
    }
  }
}

.tags {
    background-color: $white;
    border: 1px solid $black;
    box-sizing: content-box;
    font: 12px $font_noto_sans;
    text-align: center;
    div {
      span {
        @include size(7.7%, auto);
        box-sizing: border-box;
        display: inline-block;
        padding: 2px 0;
        &:nth-of-type(1) {
          @include size(7.6%, auto);
          border-right: 1px solid $black;
        }
      }
      &:nth-of-type(odd) {
        border-bottom: 1px solid $light_gray;
      }
      &:nth-of-type(2) {
        border-bottom: 2px solid $black;
      }
    }
}

.testset {
  @include flexbox(row, center, center);
  .review_wrapper {
    @include size(50%, 100%);
    font-size: 13px;
    font-weight: bold;
    line-height: 1.4em;
    overflow-y: scroll;
    padding: 0 10px;
    h3 {
      font: bold 16px $font_basic;
      margin-bottom: 10px;
    }
    .review_inner {
      div {
        &:not(:first-of-type) {
          margin-top: 5px;
        }
      }
      .choices {
        display: inline;
        &:not(:first-of-type) {
          &::before {
            content: ' / ';
          }
        }
      }
    }
  }
}

.q_format {
  display: inline;
  .brackets {
    display: inline-block;
    width: 3em;
  }
  .num {
    color: #aaa;
    font-size: 0.8em;
    margin: 0 .1em;
  }
  .underline {
    border-bottom: 2px solid #aaa;
  }
}

@media screen and (max-width: $max_width_l) {
  .arrows_wrapper {
    .arrow {
      @include size(30px, 100px);
    }
    .down {
      @include position_fixed(auto, 0, -15px, 0);
    }
    .up {
      @include position_fixed(-15px, 0, auto, 0);
    }
    .right {
      @include position_fixed(calc(50% - 30px), 20px, auto, auto);
    }
    .left {
      @include position_fixed(calc(50% - 30px), auto, auto, 20px);
    }
  }

  .user_status {
    .title {
      @include size($user_status_inner_width_s, 18%);
      margin-top: 8%;
    }
    .name {
      @include size($user_status_inner_width_s, 10%);
    }
    .table {
      @include size($user_status_inner_width_s, 55%);
      .cell,
      .cell_top {
        div {
          span {
            font-size: 15px;
          }
        }
      }
      .cell_height_double {
        div {
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }

  .history {
    .history_charts_wrapper {
      .history_charts_inner {
        .chart_wrapper {
          margin: 20px auto 20px;
        }
        .chart_small_wrapper {
          .chart_small_inner {
            .chart_small {
              h3 {
                font-size: 12px;
                margin: 0;
                text-align: center;
              }
            }
          }
          .order {
            @include size(88%, 0);
            @include position_absolute(auto, 0, 70px, 0);
            background-color: #aaa;
            .newer {
              @include position_absolute(-12px, auto, auto, 95%);
            }
          }
          .old {
            @include position_absolute(auto, auto, 25px, 10px);
          }
          .new {
            @include position_absolute(auto, 10px, 25px, auto);
          }
        }
      }
    }
  }

  .detail {
    .detail_wrapper {
      .detail_inner {
        @include size($detail_inner_width_s, 94%);
        .chart_wrapper {
          @include size(240px, 240px);
          &>div {
            @include transform(scale(.8));
            transform-origin: left;
          }
        }
        .testset {
          @include size($detail_inner_width_s, 220px);
          @include position_absolute(320px, 0, auto, 0);
        }
        .tags {
          @include size($detail_inner_width_s, auto);
          @include position_absolute(auto, 0, 10px, 0);
        }
        .react-datepicker {
          @include position_absolute(10px, auto, auto, 5px);
          @include transform(scale(.9));
          transform-origin: top left;
        }
      }
    }
  }

  .start {
    .book_wrapper {
      @include size(95%, 60%);
      @include position_absolute(35px, 0, auto, 0);
      .line_center {
        @include size(2px, 413px);
        overflow: hidden;
        path {
          stroke-width: 4px;
        }
      }
    }
  }

  .result {
    .result_wrapper {
      &:nth-of-type(1){
        @include size(42%, 83%);
      }
      &:nth-of-type(2){
        @include size(56%, 83%);
      }
      .title_wrapper {
        @include position_absolute(20px, auto, auto, 0);
      }
      .chart_wrapper {
        @include size(400px, 400px);
      }
      h3 {
        @include position_absolute(20px, auto, auto, 10px);
      }
      .score {
        @include position_absolute(15px, 10px, auto, auto);
      }
      .result_inner {
        @include size($detail_inner_width_s, calc(100% - 215px));
        .tags {
          @include size($detail_inner_width_s, auto);
        }
        .testset {
          @include size($detail_inner_width_s, 200px);
          @include position_absolute(115px, 0, auto, 0);
          .react-bootstrap-table {
            @include size(55%, 100%);
          }
          .checkbox_head {
            @include position_absolute(2px, auto, auto, 3px);
          }
        }
        .output {
          @include position_absolute(auto, auto, 10px, 5px);
        }
      }
      .advice_wrapper {
        @include size($detail_inner_width_s, 95px);
        @include position_absolute(auto, 0, 25px, 0);
      }
    }
  }

  .tags {
      font: 11px $font_noto_sans;
  }
}
