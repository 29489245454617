
.selection_wrapper {
  @include size(100%, 100%);
  @include flexbox(column, center, center);
  .selection_notest {
    @include size(60%, 10%);
    @include flexbox(column, center, center);
    background-color: $white_alpha;
    border-radius: 10px;
    margin-top: 20px;
    span {
      font: bold 22px $font_basic;
      color: $dark_gray;
    }
  }
  .selection_btns {
    @include size(100%, 60%);
    @include flexbox(row, center, space-around);
    .btn_selection {
      @include size(522px, 370px);
      border: none;
      border-radius: 0;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
      margin: 0;
    }
    .rec_active {
      background: $btn_rec_active center/cover no-repeat;
      &:hover {
        opacity: .9;
      }
    }
    .rec_inactive {
      background: $btn_rec_inactive center/cover no-repeat;
      box-shadow: none;
      cursor: unset;
    }
    .pra_active {
      background: $btn_pra_active center/cover no-repeat;
      &:hover {
        opacity: .9;
      }
    }
    .pra_inactive {
      background: $btn_pra_inactive center/cover no-repeat;
      box-shadow: none;
      cursor: unset;
    }
    .rec_active,
    .pra_active {
      &:hover,
      &:focus,
      &.focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled).active:focus
      {
        background-color: transparent;
        border: 0;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: $max_width_l) {
  .selection_wrapper {
    .selection_btns {
      .btn_selection {
        @include size(465px, 330px);
      }
    }
  }
}
