html,
body {
  @include size(100%, 100%);
  margin: 0;
  padding: 0;
}
body {
  background: $bg center/cover no-repeat;
  color: $black;
  font: 16px $font_basic;
}
.wrapper {
  @include size($max_width_l, $max_height_l);
  @include position_absolute(0,0,0,0);
}
.login_msg {
  color: $dark_gray;
  font: 20px $font_basic;
  margin: 10px;
}
.spinner{
  @include flexbox(row, center, flex-start);
  margin: 15px;
  .spinner-grow {
    @include size(2em, 2em);
    margin-right: .8em;
    margin-bottom: .3em;
  }
  .loading {
    color: $dark_gray;
    font: 24px $font_josefin_sans;
  }
}

@media screen and (max-width: $max_width_l) {
  .wrapper {
    @include size($max_width_s, $max_height_s);
  }
}
