.question {
  @include size(100%, 100%);
  position: relative;
  .bookmark_wrapper {
    @include size(400px, 82px);
    @include position_absolute(20px, 0, auto, 0);
    overflow: visible;
    .bookmark {
      @include size(100%, 100%);
      position: relative;
      .qnum {
        @include size(85%, 100%);
        @include flexbox(column, center, center);
        @include position_absolute(auto, 0, auto, auto);
        h2 {
          font-weight: bold;
          margin: 0;
          .total_count{
            font-size: .7em;
          }
        }
      }
      .bookmark_r {
        @include size(91%, auto);
        @include position_absolute(auto, auto, auto, 9%);
        overflow: visible;
        path {
          fill: $white;
          stroke: $black;
          stroke-width: 4px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
        }
      }
      .bookmark_l {
        @include size(16%, auto);
        @include position_absolute(27%, auto, auto, auto);
        overflow: visible;
        path {
          fill: $black;
          stroke: $black;
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
        }
      }
    }
  }
  .question_inner {
    @include size(90%, calc(100% - 145px));
    @include position_absolute(auto, 0, 20px, 0);
    background-color: $white;
    .qlist_mini {
      @include position_absolute(5px, auto, auto, 5px);
      @include transform(scale(0.35));
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      z-index: 999;
    }
    .button_group {
      @include size(520px, 80px);
      @include position_absolute(auto, 0, 20px, 0);
      @include flexbox(row, center, space-between);
      .btn_move {
        @include size(80px, 80px);
        border-radius: 50%;
        font-weight: bold;
        font-size: 12px;
        position: relative;
        span {
          @include position_absolute(15%, 0, auto, 0);
        }
        .move_direction {
          @include size(35%, 35%);
          @include position_absolute(45%, 0, auto, 0);
          display: block;
        }
        .rot {
          @include transform(rotate(180deg));
        }
      }
      .btn_move,
      .btn_move:focus,
      .btn_move.focus,
      .btn_move:not(:disabled):not(.disabled):active,
      .btn_move:not(:disabled):not(.disabled).active,
      .btn_move:not(:disabled):not(.disabled):active:focus,
      .btn_move:not(:disabled):not(.disabled).active:focus {
        background-color: $btn_yellow;
        border: $black 2px solid;
        box-shadow: none;
        color: $black;
        &:hover {
          opacity: .7;
        }
        &.disabled,
        &:disabled,
        &.disabled:hover,
        &:disabled:hover {
          opacity: .65;
          cursor: unset;
        }
      }
      .button_group_confidence {
        @include size(58%, 100%);
        @include flexbox(row, center, space-between);
        .btn_confidence {
          @include size(45%, 100%);
          border: solid $black 2px;
          border-radius: 22px;
          box-sizing: border-box;
          color: $black;
          font-weight: bold;
          position: relative;
          span {
            @include position_absolute(5%, 0, auto, 0);
            display: block;
            font-size: 13px;
          }
          .icon {
            @include size(52px, 52px);
            @include position_absolute(30%, 0, auto, 0);
            display: block;
            font: bold 28px $font_josefin_sans;
            line-height: 1.5em;
            .circle_outer,
            .circle_inner {
              @include position_absolute(0, 0, 0, 0);
              border-radius: 50%;
              border: 7px solid $black;
            }
            .circle_outer {
              @include size(90%, 90%);
            }
            .circle_inner {
              @include size(50%, 50%);
            }
          }
          &:disabled,
          &.disabled {
            border: solid $black 2px;
            cursor: unset;
            &:hover {
              opacity: .65;
            }
          }
          &:hover {
            opacity: .7;
          }
        }
        .conf_true {
          background-color: $btn_orange;
          &:hover {
            background-color: $btn_orange;
          }
        }
        .conf_false {
          background-color: $btn_blue;
          &:hover {
            background-color: $btn_blue;
          }
        }
        .conf_active {
          background-color: $btn_orange;
          &:hover {
            background-color: $btn_orange;
          }
        }
        .conf_active,
        .conf_active:hover,
        .conf_active:disabled,
        .conf_active.disabled,
        .conf_active:not(:disabled):not(.disabled):active,
        .conf_active:not(:disabled):not(.disabled).active,
        .conf_active:not(:disabled):not(.disabled):active:focus,
        .conf_active:not(:disabled):not(.disabled).active:focus {
          background-color: $white;
          border-color: $btn_gold;
          box-shadow: none;
          color: $btn_gold;
          .icon {
            .circle_outer,
            .circle_inner {
              background-color: $white;
              border-color: $btn_gold;
              border: 6px solid $btn_gold;
              color: $btn_gold;
            }
          }
        }
      }
    }
    .timer_wrapper {
      @include flexbox(column, center, center);
      background-color: $white;
      border: solid $black 3px;
      box-sizing: border-box;
      * {
        font-weight: bold;
        text-align: center;
      }
      h3 {
        @include size(90%, 35%);
        border-bottom: 3px solid $black;
        font-size: 15px;
        line-height: 1.8em;
        margin: 0;
      }
      .remain {
        @include size(100%, 40%);
        @include flexbox(column, center, center);
        display: block;
        font-size: 24px;
        line-height: 1.4em;
        .time_warn {
          color: #ff0000;
          font-size: 1.1em;
        }
      }
      .time_ref {
        @include size(100%, 25%);
        font-size: 11px;
      }
    }
    .timer_total {
      @include size(180px, 85px);
      @include position_absolute(auto, 15px, 15px, auto);
    }
    .timer_question {
      @include size(200px, 75px);
      @include position_absolute(15px, 15px, auto, auto);
    }
    .question_view {
      @include size(100%, 100%);
      position: relative;
      .question_view_inner {
        @include size(90%, calc(100% - 220px));
        @include position_absolute(105px, 0, auto, 0);
        font-size: 18px;
        font-weight: bold;
        overflow-y: scroll;
        padding: 10px 50px;
        * {
          font-weight: bold;
        }
        .instruction,
        .question_ {
          display: block;
          margin-bottom: .9em;
        }
        .sentence {
          display: block;
        }
        .choices {
          @include flexbox(row, stretch, space-between);
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          width: 100%;
          .btn_choice,
          .btn_choice:hover,
          .btn_choice:not(:first-child),
          .btn_choice:not(:last-child),
          .btn_choice:not(:last-child):not(.dropdown-toggle) {
            border-radius: 10px;
            font-size: 1em;
            flex: 0 0 auto;
            margin-bottom: 10px;
            padding: 15px 10px;
            text-align: left;
            width: 49%;
          }
          .btn_choice,
          .btn_choice:hover,
          .btn_choice:not(:first-child),
          .btn_choice:not(:last-child),
          .btn_choice:not(:last-child):not(.dropdown-toggle) {
            color: $black;
            border: $black 2px solid;
            background-color: $white;
            box-shadow: none;
          }
          .btn_choice:focus,
          .btn_choice.focus,
          .btn_choice:not(:disabled):not(.disabled):active,
          .btn_choice:not(:disabled):not(.disabled).active,
          .btn_choice:not(:disabled):not(.disabled):active:focus,
          .btn_choice:not(:disabled):not(.disabled).active:focus {
            color: $black;
            border: $black 2px solid;
            background-color: $light_pink;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.qlist_modal {
  max-width: 820px;
}
.qlist {
  background-color: $light_yellow;
  margin: auto;
  padding: 27px 35px 0px;
  width: 820px;
  @include flexbox(row, center, space-between);
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  .spacer{
    @include size(50px, 50px);
  }
  .btn_qlist {
    @include size(42px, 24px);
    border: none;
    border-radius: 0;
    display: block;
    margin: 0 5px 27px auto;
    padding: 0;
    position: relative;
    transition: all 0.2s linear;
    span {
      color: $black;
      display: block;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      text-align: center;
      z-index: 1;
    }
    &::before,
    &::after {
      @include size(100%, 100%);
      @include position_absolute(0, auto, auto, 0);
      content: '';
      transition: all 0.2s linear;
      z-index: 0;
    }
    &::before {
      @include transform(rotate(60deg));
    }
    &::after {
      @include transform(rotate(-60deg));
    }
    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }
  .conf_undef,
  .conf_undef.disabled,
  .conf_undef:disabled,
  .conf_undef.disabled:hover,
  .conf_undef:disabled:hover {
    background-color: #fff;
    &::before,
    &::after {
      background-color: #fff;
    }
  }
  .conf_undef:hover,
  .conf_undef:focus,
  .conf_undef.focus,
  .conf_undef:not(:disabled):not(.disabled).active,
  .conf_undef:not(:disabled):not(.disabled):active,
  .conf_undef:not(:disabled):not(.disabled).active:focus,
  .conf_undef:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background-color: $light_gray;
    &::before,
    &::after {
      box-shadow: none;
      background-color: $light_gray;
    }
  }
  .conf_true,
  .conf_true:hover,
  .conf_true:focus,
  .conf_true.focus,
  .conf_true.disabled,
  .conf_true:disabled,
  .conf_true:not(:disabled):not(.disabled).active,
  .conf_true:not(:disabled):not(.disabled):active,
  .conf_true:not(:disabled):not(.disabled).active:focus,
  .conf_true:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background-color: $btn_orange;
    &::before,
    &::after {
      box-shadow: none;
      background-color: $btn_orange;
    }
  }
  .conf_false,
  .conf_false:hover,
  .conf_false:focus,
  .conf_false.focus,
  .conf_false.disabled,
  .conf_false:disabled,
  .conf_false:not(:disabled):not(.disabled).active,
  .conf_false:not(:disabled):not(.disabled):active,
  .conf_false:not(:disabled):not(.disabled).active:focus,
  .conf_false:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background-color: $btn_blue;
    &::before,
    &::after {
      box-shadow: none;
      background-color: $btn_blue;
    }
  }
  .btn_qlist:disabled,
  .btn_qlist.disabled {
    cursor: default;
  }
}

@media screen and (max-width: $max_width_l) {
  .question {
    .title_wrapper {
      @include size(310px, 90px);
      @include position_absolute(20px, auto, auto, auto);
    }
    .bookmark_wrapper {
      @include size(360px, 82px);
      @include position_absolute(25px, 0, auto, 0);
    }
    .question_inner {
      @include size(98%, calc(100% - 145px));
    }
  }
}
