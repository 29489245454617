$break_point: 896px;

$max_width_l: 1250px;
$max_height_l: 750px;
$max_width_s: 1000px;
$max_height_s: 700px;

// STATIC FILE PATH
$logo:             url(/static/ID190226_main_logo_A0_Path_2_pattern.png);
$bg:               url(/static/ID200201_tfa_ta_bg.png);
$logo_hex:         url(/static/ID190226_parts3_1_A15_Rectangle_220_pattern.png);
$btn_rec_active:   url(/static/ai_active_1.png);
$btn_rec_inactive: url(/static/ai_inactive_1.png);
$btn_pra_active:   url(/static/test_active_1.png);
$btn_pra_inactive: url(/static/test_inactive_2.png);

// COLOR
$black:            #000;
$white:            #fff;
$white_alpha:      rgba(255,255,255,0.3);
$gray:             #aaa;
$dark_gray:        #666;
$light_gray:       #ccc;

$dull_blue:        #619fc9;
$dull_blue_alpha:  rgba(97,159,201,0.7);
$light_blue:       #a8edea;
$light_navy:       #a8cdf0;
$light_navy_alpha: rgba(168,205,240,0.7);
$light_pink:       #e9c9ce;
$light_yellow:     #efebaf;
$vivid_blue:       #3875ed;
$vivid_pink:       #ed3898;
$red:              #eb3b2f;
$red_alpha:        rgba(235,59,47,0.7);
$pink:             #ef857d;
$pink_alpha:       rgba(239,133,125,0.7);


$hex_1:            $light_navy;
$hex_2:            #99e6ff;

$table_pink:       #efb4ed;
$table_orange:     #ebb54a;
$table_blue:       $light_navy;
$table_green:      #a6f385;

$btn_yellow:       #f0eaad;
$btn_orange:       #c75b25;
$btn_blue:         #6eb5e9;
$btn_gold:         #b8860b;
$btn_pink:         $light_pink;
$btn_pink_alpha:   rgba(233,201,206,0.7);

// FONT
$font_basic: '游ゴシック Medium','Yu Gothic Medium',YuGothic,YuGothicM,'Hiragino Kaku Gothic ProN','Hiragino Kaku Gothic Pro',メイリオ,Meiryo,sans-serif;
$font_noto_sans: 'Noto Sans JP';
$font_josefin_sans: 'Josefin Sans', sans-serif;
